@import 'variables';

// Body Styles
.body-1 {
  @include font-family-normal;
  font-size: $body1;
  line-height: $line-height-body1;
}

.body-2 {
  @include font-family-normal;
  font-size: $body2;
  line-height: $line-height-body2;
}

.body-3 {
  @include font-family-normal;
  font-size: $body3;
  line-height: $line-height-body3;
}

.body-1--bold {
  @include font-family-bold;
  font-size: $body1;
  line-height: $line-height-body1;
}

.body-2--bold {
  @include font-family-bold;
  font-size: $body2;
  line-height: $line-height-body2;
}

.body-3--bold {
  @include font-family-bold;
  font-size: $body3;
  line-height: $line-height-body3;
}

.body-1--medium {
  @include font-family-medium;
  font-size: $body1;
  line-height: $line-height-body1;
}

.body-2--medium {
  @include font-family-medium;
  font-size: $body2;
  line-height: $line-height-body2;
}

// Heading Styles
.heading-1 {
  @include font-family-bold;
  font-size: $heading1;
  line-height: $line-height-heading1;
}

.heading-2 {
  @include font-family-bold;
  font-size: $heading2;
  line-height: $line-height-heading2;
}

.heading-3 {
  @include font-family-bold;
  font-size: $heading3;
  line-height: $line-height-heading3;
}

.heading-4 {
  @include font-family-bold;
  font-size: $heading4;
  line-height: $line-height-heading4;
}

.heading-5 {
  @include font-family-bold;
  font-size: $heading5;
  line-height: $line-height-heading5;
}

.heading-6 {
  @include font-family-bold;
  font-size: $heading6;
  line-height: $line-height-heading6;
}

.heading-7 {
  @include font-family-bold;
  font-size: $heading7;
  line-height: $line-height-heading7;
}

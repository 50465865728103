@import "assets/foundation-sites/settings";
@import "assets/fonts/etrainu-icons-regular/variables.scss";

@mixin animation($property: all, $duration: .3s, $function: ease ) {
  -webkit-transition: $property $duration $function;
  -moz-transition: $property $duration $function;
  -ms-transition: $property $duration $function;
  -o-transition: $property $duration $function;
  transition: $property $duration $function;
}

@mixin circle {
  border-radius: 100px;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.2);
  overflow: hidden;
}

@mixin box-shadow() {
  -webkit-box-shadow: 2px 4px 13px -3px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 4px 13px -3px rgba(0, 0, 0, 0.15);
}

@mixin bib-number() {
  padding: 3px 8px;
  border-radius: 25px;
  line-height: 1em;
  display: block;
  text-align: center;
  width: rem-calc(48px);
  font-size: .8rem;
}

@mixin event-change-dialog() {
  .exit-button {
    text-align: right;

    i {
      color: #ccc;
    }
  }
  .mat-dialog-content {
    max-height: 100vh;
  }
  [mat-dialog-content] {
    position: relative;
    [mat-dialog-close] {
      position: absolute;
      top: 0;
      right: 24px;
      z-index: 2;
    }
  }
  .modal-content {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    text-align: center;

    mat-icon, i.fa {
      height: rem-calc(42px);
      width: rem-calc(42px);
      font-size: rem-calc(42px);
    }

    h5 {
      text-align: center;
      margin-bottom: .8rem;
      font-size: 1.6rem;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .form-group {
      margin-bottom: 1.5rem;
    }

    .sub-heading {
      margin-bottom: 35px;
      text-align: center;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      &.align-center {
        justify-content: center;
      }
    }
    .icon-input {
      display: flex;
      align-items: center;

      .form-icons {
        color: #0064A8;
        margin-right: 20px;
        font-size: rem-calc(24px);
        width: rem-calc(24px);
        height: rem-calc(24px);
      }
    }
    input, textarea {
      margin-bottom: 0;
      border: none;
      border-bottom: rem-calc(1px) solid $light-gray;
      box-shadow: none;
      padding: 0;
    }
    input[type="file"] {
      opacity: 0;
      display: none;

    }
    textarea {
      max-height: 200px;
      overflow: auto;
    }
    label.checkbox {
      font-size: 1rem;
    }
    .upload-btn {
      background-color: #E12C31;
      color: white;
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 25px;
      text-align: center;
      font-weight: 600;
      margin-top: 20px;
      cursor: pointer;
      user-select: none;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .modal-content {
      mat-icon {
        font-size: rem-calc(31px);
      }
      i {
        font-size: rem-calc(42px);
        margin-bottom: rem-calc(21px);
      }
    }
  }
}

@mixin page-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    margin-left: 0.2rem;
    margin-right: 0.2rem;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }

}

@mixin active {
  .active {
    border-bottom-color: #E12C31;
    color: #232425;
    transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
  }
}

@mixin tabs {
  .event-tabs {
    display: flex;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    /*margin-top: 1rem;*/
    /*margin-left: -.9375rem;
    margin-right: -.9375rem;*/
    /*padding: 0 .9375rem;*/
    box-shadow: 0px 0px 30px -10px rgba(0,0,0,0.3);
    position: relative;
    z-index: 5;
    background: #fff;
    padding: 0;
    app-event-tab {
      &:first-child {
        margin-left: .5rem;
      }
      &:last-child {
        margin-right: .5rem;
      }
    }
    &.scrolling-tabs {
      padding: 0 2rem;
    }
    .tab {
      border-bottom: 2px solid #f3f3f3;
      cursor: pointer;
      outline: none;
      user-select: none;
      font-weight:600;
      padding: .5rem .5rem;
      text-align: center;
      font-size: .9rem;
      line-height: 1.4rem;
      text-wrap: none;
      white-space: nowrap;
      flex-grow: 1;
      &:first-child {
        margin-left: 1rem;
      }
      &:last-child {
        margin-right: 1rem;
      }
      &.disabled {
        cursor: auto;
      }
    }
    @include active
  }
}

@mixin event-search {
  .input-wrapper {
    .loading {
      margin: 0 rem-calc(10px);
    }

    .filter-menu-button {
      color: $dark-gray;
      outline: 0;
    }
    .button {
      mat-icon {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
    .filter-options-container {
      padding: 20px 0px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      font-weight: 600;

      fa {
        color: #232425;
        margin-right: 10px;
        cursor: pointer;
      }

      p {
        color: #232425;
        margin-right: 20px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .search-filters {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

@mixin event-info {
  margin-right: 15px;
  font-size: 16px;
  font-weight: 400;
}
@mixin form-group {
  .form-group {
    border: 1px solid #EAEAEA;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding-bottom: 2px;
    position: relative;
    &.error {
      border-color: $input-error-color;
    }
    &.reminder-notifications {
      .reminders {
        min-height: 3.3125rem;
      }
      .error-message{
        position: relative;
      }
    }
    .error-message {
      color: $input-error-color;
      position: absolute;
      bottom: .1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: .825rem;
    }
    &.no-border {
      border-width: 0;
    }
    @include input-field();
    .clear {
      line-height: rem-calc(24px);
      cursor: pointer;
      color: #999;
      font-size: rem-calc(10px);
    }
  }
}

@mixin input-field() {
  .input-label {
    border-bottom: 1px solid #EAEAEA;
    padding: 0 1rem;
    line-height: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    background: #F9F9F9;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 1px;

    &.has-toggle {
      padding-right: .75rem;
      justify-content: space-between;
      > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:last-child {
          justify-content: flex-end;
        }
      }
      mat-icon {
        margin-left: .5rem;
      }
    }

    .required-field {
      font-weight: normal;
      font-size: 1.2rem;
    }
  }

  .input-field {
    background: #ffffff;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    line-height: 2rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    &.no-background {
      background: none;
    }

    .toggle-field {
      padding: .5rem 1rem;

      label {
        display: flex;
        align-items: center;

        &.disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
    .ng-optgroup {
      background-color: #FFFFFF;
    }
  }

  .input-with-suffix {
    display: flex;
    align-items: center;

    .input-field {
      flex-grow: 1;
    }

    .icon-button {
      mat-icon {
        font-size: 1.1rem;
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .input-field + * {
      flex-shrink: 1;
      text-align: center;
      height: 2rem;
    }

    .input-group-append {
      display: flex;
      align-items: center;
      padding-right: .75rem;
    }
  }

  input.input-field, textarea.input-field {
    padding: 1rem;
  }
  @media screen and (max-width: 450px) {
    .input-label {
      &.has-toggle {
        flex-direction: column;
        align-items: stretch;
        line-height: 1.4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        justify-content: space-between;

        .mobile-right {
          flex-direction: column !important;
          & > * {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@mixin owl-time-picker {
  ::ng-deep owl-dialog-container {
    &.owl-dialog-container {
      min-width: 314px;
    }
    owl-date-time-calendar, owl-date-time-timer {
      width: 100% !important;
    }
  }
  ::ng-deep owl-date-time-container {
    height: 100%;

    &.owl-dt-popup-container {
      width: 20rem;
    }

    .owl-dt-container-inner {
      .owl-dt-calendar {
        border-bottom: none;
        height: auto;
        .owl-dt-calendar-view {
          table {
            margin-bottom: 0;
            thead {
              tr {
                th {
                  &.owl-dt-calendar-table-divider {
                    display: none;
                  }

                  &.owl-dt-weekday {
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #000;
                  }
                }
              }
            }

            tbody {
              tr {
                &:nth-child(even) {
                  background: none;
                }

                td {
                  .owl-dt-calendar-cell-content {
                    width: 2rem;
                    height: 2rem;

                    &.owl-dt-calendar-cell-selected {
                      background: $dark-gray;
                    }
                  }
                }
              }
            }

            tfoot {
              tr {
                td {
                  &.owl-dt-calendar-table-divider {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .owl-dt-timer {
        height: 6rem;
      }

      .owl-dt-container-buttons {
        .owl-dt-control-button {
          color: rgba(0,0,0,0.8);
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    ::ng-deep .cdk-overlay-pane.owl-dt-popup {
      left: 50% !important;
      top: 50% !important;
      bottom: auto !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
  }
}

@mixin ng-select-custom($fontSize: .75rem, $overflow: hidden, $maxCh: 20ch) {
  ::ng-deep ng-select {
    &.ng-select.ng-select-single {
      .ng-select-container {
        height: inherit;

        .ng-value-container {
          padding-left: 0 !important;

          .ng-placeholder {
            line-height: 2rem;
            padding: 0 .5rem;
          }

          .ng-value {
            border: none;

          }
        }
        .ng-arrow-wrapper {
          margin-top: 0;
        }
      }
    }

    .ng-optgroup {
      .ng-option-label {
        font-weight: bold;
      }
    }

    .ng-select-container {
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      outline: 0;

      .ng-value-container {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 5px;

        .ng-placeholder {
          line-height: 2.5rem;
          padding: 0 3px 0 3px;
          color: $dark-gray !important;
          font-size: 1rem;
        }
        .ng-value {
          border: 1px solid;
          border-radius: 1rem;
          vertical-align: middle;
          line-height: 1.4rem;
          font-size: $fontSize;
          .ng-value-label {
            line-height: 1rem;
            vertical-align: middle;
            font-weight: bold;
            overflow: $overflow;
            text-overflow: ellipsis;
            max-width: $maxCh;
            &.ellipsis-override {
              text-overflow: unset;
              max-width: none;
            }
          }
          .ng-value-clear {
            line-height: 1rem;
            border-left: none !important;
            vertical-align: middle;
            &:hover {
              background: none;
            }
            mat-icon {
              width: .7rem;
              height: .7rem;
              font-size: .7rem;
              display: block;
            }
          }
        }

        .ng-input {
          & > input {
            margin-bottom: 0;
            padding: 0;
            line-height: 2.5rem !important;

            &::-webkit-input-placeholder {
              line-height: 2.5 !important;
            }
          }
        }

        .ng-star-inserted {
          padding: 0 0.25rem;

          &.ng-value {
            .ng-value-icon {
              margin-left: -5px !important;
              padding-left: .5rem;
            }
          }
        }
      }

      .ng-clear-wrapper {
        display: block;
        line-height: 1rem;
        width: auto;
        margin-top: -1rem;

        .ng-clear {
          font-size: 2.75rem;
          display: block;
          margin-right: .5rem;
          font-weight: 200;
        }
      }

      .ng-arrow-wrapper {
        width: rem-calc(24px);
        margin-top: -.5rem;
        .ng-arrow {
          border-style: solid !important;
          border-width: 0 2px 2px 0 !important;
          content: '' !important;
          display: inline-block !important;
          padding: 3px !important;
          vertical-align: middle !important;
          border-color: rgba(0, 0, 0, .54);
          transform: rotate(45deg);
          top: 0;
          margin-top: rem-calc(-8px);
        }
      }
      &:hover {
        box-shadow: none;
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        .ng-arrow-wrapper {
          .ng-arrow {
            transform: rotate(225deg);
            border-color: rgba(0, 0, 0, .54) !important;
            top: 0;
          }
        }
      }
    }
  }
}

@mixin ng-select-single() {
  ::ng-deep {
    ng-select.ng-select.ng-select-single {

      .ng-select-container {
        border: none;
        border-bottom: 1px solid;
        border-bottom-color: $gray-300;
        border-radius: 0;

        &:hover {
          box-shadow: none;
        }

        .ng-value-container {
          padding-left: 0;
          padding-right: .75rem;

          .ng-input {
            padding-left: 0;
          }

          .ng-placeholder {
            color: $gray-500;
          }

        }

      }

      &.ng-select-opened {

        &.ng-select-bottom {

          & > .ng-select-container {
            border-bottom: 2px solid;
            border-bottom-color: inherit;
          }

          .ng-value-container {
            margin-top: 1px;
          }
        }
        .ng-arrow-wrapper {

          .ng-arrow {
            top: 1px;

            &::before {
              content: $icon-chevron-up;
            }
          }

        }

      }

      &.ng-select-focused:not(.ng-select-opened) {

        & > .ng-select-container {
          border-bottom-color: $gray-300;
          box-shadow: none;
        }
      }

      &.ng-select-disabled {

        .ng-select-container {
          background-color: transparent;

          .ng-value-container {

            .ng-placeholder {
              color: $gray-300;
            }

          }

          .ng-arrow-wrapper {

            .ng-arrow {
              &::before {
                color: $gray-300;
              }

            }

          }

        }

      }

      .ng-arrow-wrapper {
        position: relative;

        .ng-arrow {
          border: none;
          height: 1rem;
          width: 1rem;
          display: block;
          &::before {
            display: block;
            font-family: "etrainu-icon-regular";
            content: $icon-chevron-down;
            font-size: 1rem;
            height: 1rem;
            width: 1rem;
            line-height: 1rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $gray-500;
          }
        }

      }

      .ng-clear-wrapper {
        width: .75rem;
        height: .75rem;
        box-sizing: content-box;
        padding-right: .75rem;

        .ng-clear {
          display: block;
          content: "";
          position: relative;
          font-size: 0;

          &::before {
            display: block;
            content: $icon-close;
            font-family: "etrainu-icon-regular";
            font-size: .75rem;
            height: .75rem;
            width: .75rem;
            line-height: .75rem;
            color: $gray-500;
            font-weight: bold;
          }

        }

      }

    }

  }
}

@mixin ng-select-dropdown() {
  .ng-dropdown-panel {
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    transition: opacity 0.1s linear;

    &.ng-select-bottom {
      margin-top: 2px;
    }
    .ng-dropdown-panel-items {
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;

      @extend .body-2;

      .ng-option {

        &.ng-option-marked {
          background-color: $gray-100;
        }

        &.ng-option-selected {
          background-color: $gray-300;
        }

        &.ng-option-marked.ng-option-selected {
          background-color: $gray-300;
        }

      }

    }

  }

}

@mixin pager {
  .pager {
    font-size: rem-calc(14px);
    li {
      padding: 0 rem-calc(10px) !important;
      border-radius: 5px;
    }
    .active {
      background-color: #E12C31;
      a {
        color: #fff;
      }
    }
    a {
      color: #E12C31;
    }
    .disabled {
      visibility: hidden;
    }
  }
}

@mixin error-msg {
  .error-msg {
    font-size: 15px;
    color: #E12C31;
  }
}

@mixin top-search-bar {
  .top-search-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    .input-wrapper {
      flex-grow: 1;
    }
    .last-input {
      display: flex;
      flex-direction: row;
      .input-wrapper {
        margin-right: 8px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .top-search-bar {
      flex-direction: row;
      .input-wrapper {
        width: 50%;
        margin-right: 8px;
      }
      .last-input {
        width: 50%;
      }
    }
  }
}
